import InputField from "./InputField";
import { FormData, submitForm } from "../formData";
import React, { Dispatch, SetStateAction, useState } from "react";
import { ServerResponse, ServerResponseSchema } from "../schema";
import { State } from "../state";
import { DropDown } from "./DropDown";

export const InputForm: React.FC<{
  setState: Dispatch<SetStateAction<State>>;
}> = ({ setState }) => {
  const [submit, setSubmit] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    mood: "",
    locale: "",
    distance: undefined,
  });
  React.useEffect(() => {
    if (submit) {
      submitForm(formData)
        .then(ServerResponseSchema.parseAsync)
        .then((response: ServerResponse) => {
          switch (response.kind) {
            case "response":
              setState({
                type: "received response",
                response,
              });
              break;
            case "error":
              setState({ type: "error", message: response.error });
              break;

            default:
              setState({
                type: "error",
                message: `Invalid response kind: ${JSON.stringify(response)}`,
              });
          }
        })
        .catch((error) => {
          // Catch and handle any errors during form submission.
          setState({ type: "error", message: error.message });
        });
    }
    return () => setSubmit(false);
  }, [formData, setState, submit]); // Dependencies for the effect.

  // Function to update the form data state when an input changes.
  const handleInputChange = (name: string, value: string) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Function to handle form submission, updating the app state.
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault(); // Prevent the form from refreshing the page.
    setSubmit(true);
  };

  return (
    <div className="inset-x-0 top-4 flex items-end justify-center">
      <div className="max-w-sm px-4 py-8 space-y-3 rounded-xl bg-white bg-opacity-90 shadow-xl">
        <h1 className="text-2xl md:text-3xl font-bold text-center">
          The Food Mood Machine
        </h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="flex items-center space-x-2">
            {/* InputField component with flex-grow to ensure it takes available space */}
            <div className="flex-grow">
              <InputField
                label="Food Mood"
                value={formData.mood}
                onChange={(e) => handleInputChange("mood", e)}
                placeholderText="Your mood for food"
              />
            </div>

            {/* Info icon with tooltip, without flex-grow to ensure it stays to the right */}
            <div className="relative flex-shrink-0">
              <div className="flex items-center justify-center w-6 h-6 bg-gray-200 text-gray-800 rounded-full cursor-pointer group">
                <span>i</span>
                {/* Tooltip text (shown on hover) */}
                <div className="absolute hidden w-48 px-2 py-1 text-sm text-white bg-gray-700 rounded-lg -left-12 top-8 group-hover:block">
                  Enter your current mood to get a restaurant suggestion.
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            {/* InputField component with flex-grow to ensure it takes available space */}
            <div className="flex-grow">
              <InputField
                label="Location"
                value={formData.locale}
                onChange={(e) => handleInputChange("locale", e)}
                placeholderText="Neighborhood or City, and State"
              />
            </div>

            {/* Info icon with tooltip, without flex-grow to ensure it stays to the right */}
            <div className="relative flex-shrink-0">
              <div className="flex items-center justify-center w-6 h-6 bg-gray-200 text-gray-800 rounded-full cursor-pointer group">
                <span>i</span>
                {/* Tooltip text (shown on hover) */}
                <div className="absolute hidden w-48 px-2 py-1 text-sm text-white bg-gray-700 rounded-lg -left-12 top-8 group-hover:block">
                  Provide a Neighborhood, City, or Zipcode. If you don't use a
                  zipcode, be sure to include the state as well.
                </div>
              </div>
            </div>
          </div>

          <DropDown
            onChange={(e) =>
              setFormData((prevData) => ({
                ...prevData,
                distance: parseInt(e.target.value),
              }))
            }
            value={formData.distance}
          />
          <button
            type="submit"
            className="w-full px-4 py-2 text-white bg-black rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
          >
            FILL MY FOOD MOOD
          </button>
        </form>
      </div>
    </div>
  );
};
