import { IconBaseProps } from 'react-icons';
import { FaAmazon } from 'react-icons/fa';

interface ExtendedIconBaseProps extends IconBaseProps {
    className?: string;
}

const AmazonIcon: React.FC<ExtendedIconBaseProps> = (props) => (
    <FaAmazon {...props} />
);

type AffiliatesProps = {
    affiliate: string;
};

const Affiliates: React.FC<AffiliatesProps> = ({ affiliate }) => {
    switch (affiliate) {
        case "amazon":
            return (
                // Affiliate advertisement box
                <a
                    className="inline-flex justify-center items-center w-3/4 bg-amazon-orange hover:bg-amazon-dark-orange 
                             text-white font-bold py-2 px-4 rounded shadow-lg transition-all duration-300 ease-in-out 
                             transform hover:scale-105"
                    target="_blank"
                    href="https://www.amazon.com/gp/search?ie=UTF8&tag=foodmood01-20&linkCode=ur2&linkId=c0ffb3b9d3217c974e8ba4c8d55e872c&camp=1789&creative=9325&index=kitchen&keywords=Lodge"
                    rel="noopener noreferrer"
                    aria-label="Shop Now on Amazon" // Accessibility improvement
                >
                    <AmazonIcon className="mr-2 w-6 h-6" /> {/* Adding an icon */}
                    Shop Now on Amazon
                </a>
            );
        default:
            return null; // Returning null for cases where the affiliate is not recognized
    }
};

export default Affiliates;
