import CreateFooter from "../components/CreateFooter";
import CreateHeader from "../components/CreateHeader";

const AboutPage = () => {
    return (
        /* The container div is used to center the content on the page. */
        <div className="grid max-h-full place-items-center ">
            <CreateHeader logoPath="src/Food_Mood_Logo.png" />
            {/* This will be a single column grid on small screens and 3 columns on medium screens and up */}
            {/*The first row in the grid*/}

            <div className="w-3/4 h-auto flex items-center justify-center text-center bg-gray-400 py-12 px-4 mt-24 md:mt-36 mb-10 rounded-lg shadow-md">
                <p>
                    <a target="_blank" href="https://www.amazon.com/b?_encoding=UTF8&tag=foodmood01-20&linkCode=ur2&linkId=92689b05677b8be3a0037651441816a0&camp=1789&creative=9325&node=2255571011">Test</a>
                </p>
                <p>
                    <a target="_blank" href="https://www.amazon.com/gp/search?ie=UTF8&tag=foodmood01-20&linkCode=ur2&linkId=4f0d7e11ed849d41944e1980acac63f2&camp=1789&creative=9325&index=grocery&keywords=Food">Search Result Test</a>
                </p>
            </div>

            {/* Orange Box where content will go in Response*/}
            <div className="grid w-3/4 grid-cols-1 text-center bg-orange-100 bg-opacity-50 py-12 px-4 mt-24 md:mt-36 mb-10 rounded-lg shadow-md">
                <p className="text-xl italic font-bold text-gray-800 pt-10 antialiased">
                    Where foodies find food and the rest of us just eat.
                </p>
                <p className="pt-4 pb-8 text-lg font-semibold">
                    Good Food - Good Mood
                </p>
            </div>
            <CreateFooter />
        </div>
    );
};
export default AboutPage;
