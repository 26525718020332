import logo from "../Food_Mood_Logo.png";
import React, { useState } from 'react';
import { Link } from 'react-router-dom';


const CreateHeader = ({ logoPath }: { logoPath: string }) => {
    const [showMenu, setShowMenu] = useState(false);

    return (
        <nav className="flex w-full items-center justify-between flex-wrap bg-white px-6 py-3 shadow-lg mb-4">
            <div className="flex items-center flex-shrink-0 text-gray-900 mr-6">
                <img className="w-24 h-20 sm:w-32 sm:h-24 md:w-36 md:h-28" src={logo} alt="Logo" />

                <span className="font-semibold text-2xl md:text-3xl tracking-tighter">Food Mood</span>
            </div>
            <div className="block lg:hidden">
                <button
                    onClick={() => setShowMenu(!showMenu)}
                    className="flex items-center px-3 py-2 border rounded text-gray-800 border-gray-400 hover:text-blue-500 hover:border-blue-500">
                    <svg className="fill-current h-4 w-4" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <title>Menu</title>
                        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                    </svg>
                </button>
            </div>
            <div className={`${showMenu ? "block" : "hidden"} w-full flex-grow lg:flex lg:items-center lg:justify-end lg:w-auto`}>
                <div className="text-md lg:flex-grow">
                    <Link to="/" className="block mt-4 lg:inline-block lg:mt-0 text-gray-700 hover:text-blue-600 px-4 py-2">
                        Home
                    </Link>
                    <Link to="/foodblog" className="block mt-4 lg:inline-block lg:mt-0 text-gray-700 hover:text-blue-600 px-4 py-2">
                        Food Blog
                    </Link>
                    <Link to="/about" className="block mt-4 lg:inline-block lg:mt-0 text-gray-700 hover:text-blue-600 px-4 py-2">
                        About
                    </Link>
                    {/* Add more links as needed */}
                </div>
            </div>
        </nav>
    );
};


export default CreateHeader;

