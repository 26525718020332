export type FormData = {
  mood: string;
  locale: string;
  distance: number | undefined;
};

// Asynchronous function to submit the form data to the server.
// It sends a POST request and returns the parsed JSON response.
export async function submitForm(formData: FormData) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/dev`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });
  return await response.json();
}
