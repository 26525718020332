interface InputFieldProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  placeholderText?: string;
}

const InputField: React.FC<InputFieldProps> = ({ label, value, onChange, placeholderText }) => (
  <div className="mb-4">
    <label className="block text-gray-700 text-lg font-bold mb-2" >
      {label}
      <input
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="shadow appearance-none border rounded-md w-full py-2 pl-3 pr-3 text-gray-700 leading-tight text-base  
        placeholder:italic placeholder:text-slate-400 block bg-white w-full border-slate-300  
        focus:outline-none focus:placeholder-text-transparent focus:border-custom-blue focus:ring-custom-blue focus:ring-1  
        focus:shadow-outline focus:bg-blue-100
        hover:border-custom-blue hover:ring-custom-blue hover:ring-1"
        placeholder={placeholderText}
        name="search" />
    </label>
  </div>
);

export default InputField;
