import CreateFooter from "../components/CreateFooter";
import CreateHeader from "../components/CreateHeader";

const AboutPage = () => {
  return (
    /* The container div is used to center the content on the page. */
    <div className="grid max-h-full place-items-center ">
      <CreateHeader logoPath="src/Food_Mood_Logo.png" />
      {/* This will be a single column grid on small screens and 3 columns on medium screens and up */}
      {/*The first row in the grid*/}

      <div className="w-3/4 h-auto flex items-center justify-center text-center bg-gray-400 py-12 px-4 mt-24 md:mt-36 mb-10 rounded-lg shadow-md">
        <p className="font-medium">
          At Food Mood we believe that good food brings good moods and that we
          can all use a little more good moods in our lives.
          <br />
          <br />
          We also believe finding the right restaurant to match your mood can be
          a challenge. Food Mood is a fun and interactive website to help you
          find your next favorite restaurant. The Food Mood Machine will find
          you a specific restaurant based upon your mood and location. Whether
          it is a kid's birthday party or a romantic date night, Food Mood can
          help you find the right spot. Food Mood mixes a little art and a
          little science to provide you the best results. There are times when
          the Food Mood Machine may fail to properly identify a great place to
          eat. Please give it another go and the Food Mood Machine should be
          able to fill your mood. We are constantly working to improve in inner
          workings of the Food Mood Machine to better support you.
          <br />
          You are invitied to join our community and share your Food Mood
          experiences with us.
          <br />
          <br />
          <br />
          We also believe in giving back and serving our community, local and
          global. Every month we are committed to donating 10 percent of our
          profits to local and global charities.
          <br />
          <br />
          Good Food brings Good Moods.
        </p>
      </div>

      {/* Orange Box where content will go in Response*/}
      <div className="grid w-3/4 grid-cols-1 text-center bg-orange-100 bg-opacity-50 py-12 px-4 mt-24 md:mt-36 mb-10 rounded-lg shadow-md">
        <p className="text-xl italic font-bold text-gray-800 pt-10 antialiased">
          Where foodies find food and the rest of us just eat.
        </p>
        <p className="pt-4 pb-8 text-lg font-semibold">
          Good Food - Good Mood
        </p>
      </div>
      <CreateFooter />
    </div>
  );
};
export default AboutPage;
