import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AboutPage from "./pages/AboutPage";
import HomePage from "./pages/HomePage";
import AffiliatesPage from "./pages/AffiliatesPage";
import FoodBlogPage from "./pages/FoodBlogPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/affiliates" element={<AffiliatesPage />} />
        <Route path="/foodblog" element={<FoodBlogPage />} />
      </Routes>
    </Router>
  );
}
export default App;
