import React from "react";

const CreateFooter = () => {
    return (
        <div className="grid w-3/4 grid-cols-1 text-center bg-gray-200 py-12 px-4 rounded-lg shadow-md">
            <p className="font-bold"> </p>
            <p></p>
            <p className="text-sm">  Disclaimer: While the Food Mood Machine does it's best to provide you with fun and accurate
                descriptions of restaurants, it is not responsible for inaccurate information.
                It has been known to identify restaurants slightly outside your radius and can get a little squirrely.
                This tool is meant to be used for fun and entertainment purposes only.
                Please use your own discretion when choosing a restaurant.</p>
            <p className="text-sm pt-8">  The content of Food Mood includes affiliate links through which we earn a
                commission at no extra cost to you if you make a purchase through them. Our website is committed to providing
                informative and unbiased reviews, recommendations, and insights to help our readers make informed decisions.
                The affiliates have no impact on the restaurant search results and recommendation.</p>
        </div>
    );
};

export default CreateFooter;