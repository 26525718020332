import React, { ChangeEvent, useState } from 'react';
import CreateFooter from "../components/CreateFooter";
import CreateHeader from "../components/CreateHeader";

const BlogPage = () => {

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedPostType, setSelectedPostType] = useState('');

    //Dummy blog post data
    const [posts, setPosts] = useState([
        {
            id: 1,
            title: 'Egg Bistro',
            location: 'Chesapeake, VA',
            input: 'Fun family brunch spot!',
            content: '',
            foodMoodMachineRecommendation: 'Food Mood Recommendation: Start your Sunday off right with a delightful brunch experience at The Egg Bistro. This family-friendly restaurant offers a cozy and inviting atmosphere, perfect for spending quality time with your loved ones. Indulge in their mouthwatering brunch options such as fluffy pancakes, crispy bacon, eggs benedict, and their famous omelets made with farm-fresh ingredients. Don\'t forget to try their signature Bloody Mary or refreshing mimosa to accompany your meal. With friendly staff and a wide variety of options for every palate, The Egg Bistro is sure to be a hit with the whole family on your relaxing Sunday brunch outing.',
            imageUrl: 'https://lirp.cdn-website.com/0cb7f980/dms3rep/multi/opt/342417677_1400544194081445_5297530337474476761_n-1920w.jpg',
            restaurantName: 'Restaurant Name',
            postType: 'Restaurant Review',
        },
        {
            id: 2,
            title: 'Captain Chuck-A-Muck\'s',
            location: 'Carrolton, VA',
            content: 'This place is amazing.  The food is great and the atmosphere is just as good. You can eat your delicious she crab soup and crab cakes while you stare at the slowly moving river.',
            foodMoodMachineRecommendation: 'Ahoy mateys! If you\'re craving some delicious seafood in a fun and lively atmosphere, head over to Captain Chuck-A-Muck\'s. They serve up fresh catches of the day in generous portions that will leave you feeling like a true seafood connoisseur. Plus, the pirate-themed decor will transport you to the high seas without ever leaving Carrolton. So grab your crew and set sail for a dining adventure at Captain Chuck-A-Muck\'s! Arrrgh!',
            imageUrl: 'https://images.squarespace-cdn.com/content/v1/5afb736c85ede1792a242519/1530800845374-BUAFL3083I8Q0NPN195I/Screen+Shot+2018-07-05+at+10.25.47+AM.png?format=750w',
            restaurantName: 'Captain Chuck-A-Muck\'s',
            postType: 'Restaurant Review',

        },
        // Add more posts as needed
    ]);

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    // Filter posts based on the search term
    const filteredPosts = posts.filter(post =>
        (post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            post.content.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (selectedLocation ? post.location === selectedLocation : true) &&
        (selectedPostType ? post.postType === selectedPostType : true)
    );


    return (
        /* The container div is used to center the content on the page. */
        <div className="grid max-h-full place-items-center ">
            <CreateHeader logoPath="src/Food_Mood_Logo.png" />

            {/* Blog posts */}
            <div className="max-w-4xl mx-auto pt-8">
                <div className="mb-4">
                    <input
                        type="text"
                        placeholder="Search posts..."
                        className="px-4 py-2 border rounded-lg"
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {/* Location dropdown filters */}
                    <select
                        className="ml-2 px-4 py-2 border rounded-lg"
                        value={selectedLocation}
                        onChange={(e) => setSelectedLocation(e.target.value)}>
                        <option value="">Location</option>
                        {Array.from(new Set(posts.map(post => post.location))).map(location => (
                            <option key={location} value={location}>{location}</option>
                        ))}
                    </select>

                    {/* Post type dropdown filters */}
                    <select
                        className="ml-2 px-4 py-2 border rounded-lg"
                        value={selectedLocation}
                        onChange={(e) => setSelectedPostType(e.target.value)}>
                        <option value="">Post Type</option>
                        {Array.from(new Set(posts.map(post => post.postType))).map(location => (
                            <option key={location} value={location}>{location}</option>
                        ))}
                    </select>
                </div>

                {filteredPosts.map((post) => (
                    <div key={post.id} className="flex mb-8 p-6 bg-white shadow-lg rounded-lg">

                        {/* Image Container */}
                        <div className="flex-none">
                            <img src={post.imageUrl} alt={post.title} className="rounded-lg w-32 h-32 object-cover mr-4" />
                        </div>

                        {/* Post Content */}
                        <div>
                            <h2 className="text-xl font-bold mb-2">{post.title}</h2>
                            <p className='pb-2'>{post.location}</p>
                            <p >{post.content}</p>
                            <p></p>
                            <p className='pt-4'> Food Mood Machine Recommendation: {post.foodMoodMachineRecommendation}</p>
                        </div>
                    </div>
                ))}
            </div>
            {/* Orange Box where content will go in Response*/}
            <div className="grid w-3/4 grid-cols-1 text-center bg-orange-100 bg-opacity-50 py-12 px-4 mt-24 md:mt-36 mb-10 rounded-lg shadow-md">
                <p className="text-xl italic font-bold text-gray-800 pt-10 antialiased">
                    Where foodies find food and the rest of us just eat.
                </p>
                <p className="pt-4 pb-8 text-lg font-semibold">
                    Good Food - Good Mood
                </p>
            </div>
            <CreateFooter />
        </div>
    );
};
export default BlogPage;
