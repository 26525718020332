//Sources the url and embeds the google map
export const EmbedGoogleMap: React.FC<{
  googleURL: string | undefined;
}> = ({ googleURL }) => (
  <iframe
    title="Embed Google Map"
    className="w-full h-64 shadow-xl rounded-xl"
    loading="lazy"
    src={googleURL}
    allowFullScreen
  />
);
