export const distanceOptions = [1, 5, 10, 15, 20];
export function distanceString(num: number) {
  return `${num} mile${num > 1 ? "s" : ""}`;
}

export const DropDown: React.FC<{
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value: string | number | readonly string[] | undefined;
}> = ({ onChange, value }) =>
  // value: string | number | readonly string[] | undefined
  {
    return (
      <div key={"distance"} className="mb-4">
        <label className="block text-gray-700 text-lg font-bold mb-2">
          Distance
          <select
            className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            value={value}
            onChange={onChange}
          >
            <option value="">Select a distance</option>
            {distanceOptions.map((option: number, index: number) => (
              <option key={index} value={option}>
                {distanceString(option)}
              </option>
            ))}
          </select>
        </label>
      </div>
    );
  };
