import React, { useState, useRef, useEffect } from "react";
import CreateHeader from "../components/CreateHeader";
import CreateFooter from "../components/CreateFooter";
import restaurantImage from "../image_of_food.jpg";
import { EmbedGoogleMap } from "../embedGoogleMap";
import { Submitted } from "../submitted";
import AboutPage from "./AboutPage";
import { State } from "../state";
import { InputForm } from "../components/InputForm";
import Affiliates from "../components/Affiliates";

const App: React.FC = () => {
  // State hook to manage the application state.
  const [state, setState] = useState<State>({
    type: "idle",
  });

  // Create a ref for the search results section
  const searchResultsRef = useRef<HTMLDivElement>(null);

  // Automatically scroll to the search results when the state is "received response"
  useEffect(() => {
    if (state.type === "received response" && searchResultsRef.current) {
      searchResultsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [state.type]); // Depend on state.type to trigger the effect only when it changes


  // State hook to manage the form data.
  const LocalInputForm = () => <InputForm setState={setState} />;

  const HomePageTopSection = () => {
    const formRef = useRef(null);
    return (
      <div className="flex flex-col items-center justify-center space-y-4">
        <CreateHeader logoPath="src/Food_Mood_Logo.png" />
        <div className="w-3/4 h-auto">
          <div className="relative w-full mx-auto max-h-80 border-b-4 border-t-4 border-gray-800 rounded-lg overflow-hidden">
            <img className="w-full h-full object-cover" src={restaurantImage} alt="restaurantImage" />
          </div>
        </div>
        <p className="text-lg md:text-xl lg:text-2xl font-semibold text-gray-900 mt-4 mb-4 mx-auto text-center leading-relaxed">
          Provide your current mood and the <span className="text-orange-500">Food Mood Machine</span> will recommend a restaurant for you.
        </p>
        <div className="w-full sm:max-w-md">
          <LocalInputForm />
        </div>
      </div>

    );
  };

  // Based on the current state, render different UI components.
  // The `switch` statement below determines which UI component to render based on the current state of the app.
  switch (state.type) {
    // When the app is in its initial state ("idle"), a form is displayed.
    case "idle":
      return (
        /* The container div is used to center the content on the page. */
        <div className="grid max-h-full place-items-center ">
          <HomePageTopSection />
          {/* /Affiliate advertisement box */}
          <div className="flex justify-center items-center w-1/4 bg-gray-500 rounded-lg h-32 mt-4">
            <Affiliates affiliate="amazon" />
          </div>
          {/* Orange Box where content will go in Response*/}
          <div className="grid w-3/4 grid-cols-1 text-center bg-orange-100 bg-opacity-50 py-12 px-4 m-10 rounded-lg shadow-md">
            <p className="text-xl italic font-bold text-gray-800 pt-20 antialiased">
              Where foodies find food and the rest of us just eat.
            </p>
            <p className="pt-4 pb-8 text-lg font-semibold">
              Good Food - Good Mood
            </p>
          </div>
          {/* /Affiliate advertisement box */}
          <div className="flex justify-center items-center w-1/4 bg-gray-500 rounded-lg h-32 mb-10">
            <Affiliates affiliate="amazon" />
          </div>
          <CreateFooter />
        </div>
      );

    // When the form has been submitted, a summary of the entered data is displayed.
    case "submitted":
      // TODO: You might want to display some kind of loading indicator while waiting for the OpenAI API response.
      return <Submitted />;

    // After receiving a response from the OpenAI API, this message is displayed.
    // The actual content from the response might be displayed in a real-world scenario.
    case "received response":
      const { restaurant, recommendation } = state.response.restaurantInfo;
      const {
        opening_hours,
        formatted_address,
        formatted_phone_number,
        website,
      } = state.response.place;
      const googleMapURL = state.response.googleMapURL;
      return (
        /* The container div is used to center the content on the page. */
        <div className="grid max-h-full place-items-center ">
          <HomePageTopSection />
          {/* /Affiliate advertisement box */}
          <div className="flex justify-center items-center w-1/4 bg-gray-500 rounded-lg h-32 mt-4">
            <Affiliates affiliate="amazon" />
          </div>
          {/* Orange Box: Restaurant and business data*/}
          {/* TODO: The Scroll to search result is not working everytime. It only works on the first search. */}
          <div ref={searchResultsRef} className="grid w-3/4 grid-cols-1 text-center bg-orange-100 bg-opacity-50 py-12 px-4 mt-24 md:mt-36 mb-10 rounded-lg shadow-md">            <p className="mt-2 mb-8 text-2xl"><span className="font-medium text-2xl">{restaurant}</span></p>
            <p><span className="text-xl">{recommendation}</span></p>
            <div className="relative w-full shadow-xl rounded-xl overflow-hidden mt-12" >
              <EmbedGoogleMap googleURL={googleMapURL} />
            </div>
            <div className="mt-4 w-auto sm:max-w-md py-10 md:py-4 bg-gray-200 border-2 border-gray-800 rounded-lg">
              <h3 className="font-semibold">Opening Hours:</h3>
              {opening_hours.weekday_text && (
                <ul className="list-disc text-left px-8 text-sm sm:text-base">
                  {opening_hours.weekday_text.map((day, index) => (
                    <li key={index} className="mt-1">{day}</li>
                  ))}
                </ul>
              )}
            </div>

            <p className="font-bold py-1">Address: <span className="font-normal">{formatted_address}</span></p>
            <p className="font-bold py-1">Phone Number:{" "}<span className="font-normal">{formatted_phone_number}</span></p>
            <p className="font-bold py-1">Website:{" "}{website && (
              <a
                href={website}
                className="font-medium text-custom-blue hover:text-gray-500 px-1 rounded"
                target="_blank"
                rel="noopener noreferrer"
              >
                {website}
              </a>
            )}
            </p>
            <p className="text-xl italic font-bold text-gray-800 pt-20 antialiased">
              Where foodies find food and the rest of us just eat.
            </p>
            <p className="pt-4 pb-8 text-lg font-semibold">
              Good Food - Good Mood
            </p>
          </div>
          {/* /Affiliate advertisement box */}
          <div className="flex justify-center items-center w-1/4 bg-gray-500 rounded-lg h-32 mb-10">
            <Affiliates affiliate="amazon" />
          </div>
          <CreateFooter />
        </div>
      );

    //Create about page
    case "about":
      return <AboutPage />;

    // If there's an error, display the error message.
    case "error":
      return (
        /* The container div is used to center the content on the page. */
        <div className="grid max-h-full place-items-center ">
          <HomePageTopSection />
          {/* /Affiliate advertisement box */}
          <div className="flex justify-center items-center w-1/4 bg-gray-500 rounded-lg h-32 mt-4">
            <Affiliates affiliate="amazon" />
          </div>
          {/* Orange Box: Restaurant and business data*/}
          <div ref={searchResultsRef} className="grid w-3/4 grid-cols-1 text-center bg-orange-100 bg-opacity-50 py-12 px-4 mt-24 md:mt-36 mb-10 rounded-lg shadow-md">
            <div className="w-auto py-10 md:py-4 text-center rounded-xl shadow-xl bg-gray-200 border-2 border-gray-800">
              <p className="font-bold px-4 ">{state.message};</p>
            </div>
            <p className="text-xl italic font-bold text-gray-800 pt-20 antialiased">
              Where foodies find food and the rest of us just eat.
            </p>
            <p className="pt-4 pb-8 text-lg font-semibold">
              Good Food - Good Mood
            </p>
          </div>

          {/* /Affiliate advertisement box */}
          <div className="flex justify-center items-center w-1/4 bg-gray-500 rounded-lg h-32 mt-4">
            <Affiliates affiliate="amazon" />
          </div>
          <CreateFooter />
        </div>
      );
  }
};

export default App;
