import { z } from "zod";

const RestaurantInfoSchema = z.object({
  restaurant: z.string().nullable(),
  recommendation: z.string().nullable(),
});

const OpeningHoursSchema = z.object({
  open_now: z.boolean(),
  weekday_text: z.array(z.string()),
});

const PlaceDataSchema = z.object({
  opening_hours: OpeningHoursSchema,
  formatted_address: z.string(),
  formatted_phone_number: z.string(),
  website: z.string().optional(),
});

const OkSchema = z.object({
  kind: z.literal("response"),
  restaurantInfo: RestaurantInfoSchema,
  place: PlaceDataSchema,
  googleMapURL: z.string(),
});
export type Ok = z.infer<typeof OkSchema>;

const ErrorSchema = z.object({
  kind: z.literal("error"),
  error: z.string(),
});

export const ServerResponseSchema = ErrorSchema.or(OkSchema);
export type ServerResponse = z.infer<typeof ServerResponseSchema>;
